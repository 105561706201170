/* Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
    display: none;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
}
/* IE, Edge and Firefox */
.hide-scroll {
    -ms-overflow-style: none;  /* IE and Edge 
    scrollbar-width: none;  /* Firefox */
    width: 100%;
    white-space: nowrap;
    overflow: auto;
}

.Lsvg {
    font-family: 'Alegreya', serif;
    font-weight: 550;
}

.Rsvg {
    font-family: 'Alegreya', serif;
    font-weight: 900;
}

.App > * {
    grid-column: 2;
}

.App {
    display: grid;
    grid-template-rows:
      min-content 
      auto 
      min-content;
    grid-template-columns:
      1fr
      min(66vh, 100% - calc(2 * var(--standard-p)))
      1fr;
}

/* TODO: var(--standard-p) is now flexible, so 308px may not always be accurate? 
@media screen and (max-width: 308px) { 
    #root {
        width: max(300px + 2 * var(--standard-p), 100%);
    }
    .App {
        grid-template-columns:
            var(--standard-p)
            max(300px, calc(100% - 2 * var(--standard-p)))
            var(--standard-p);
    }
}
/* 
@media screen and (orientation: landscape) and (max-height: 600px) {
    .App {
        grid-template-columns:
            var(--standard-p)
            max(300px, calc((100% - 3 * var(--standard-p)) / 2))
            max(300px, calc((100% - 3 * var(--standard-p)) / 2))
            var(--standard-p);
    }
} */

.hover {
    cursor: pointer;
}

.small-caps {
    font-variant: small-caps;
}

.MuiButton-root {
    font-size: inherit !important;
    padding: calc(1 * var(--standard-p)) calc(3 * var(--standard-p)) !important;
}

.MuiButton-startIcon > * {
    font-size: inherit !important;
}

.MuiSnackbar-root {
    bottom: 0 !important;
    left: 0 !important;
    padding: 0 3vw calc(2 * var(--textFontSize) + var(--standard-p)) 3vw !important; /* Footer height + padding */
}

.MuiSnackbarContent-root {
    padding: var(--standard-p) calc(2 * var(--standard-p)) !important;
}

.MuiSnackbarContent-message {
    padding: var(--standard-p) calc(2 * var(--standard-p)) !important;
    font-size: calc(7/8 * var(--textFontSize)) !important;
}

.MuiSnackbarContent-action {
    font-size: calc(7/8 * var(--textFontSize)) !important;
}

.MuiSnackbar-root {
    font-size: calc(7/8 * var(--textFontSize)) !important;
}

.MuiAlert-root {
    font-size: calc(7/8 * var(--textFontSize)) !important;
    width: 100%;
    padding: calc(2 * var(--standard-p)) calc(3 * var(--standard-p)) !important;
}

.MuiAlertTitle-root {
    font-size: calc(7/8 * var(--textFontSize)) !important;
}

.MuiAlert-icon {
    font-size: calc(7/8 * var(--textFontSize)) !important;
    padding: calc(1.5 * var(--standard-p)) 0 !important;
}

.MuiAlert-message {
    padding: var(--standard-p) 0 !important;
}

.MuiAlert-action {
    padding: var(--standard-p) 0 0 calc(2 * var(--standard-p));
}

a {
    color: inherit;
}

.autoScaleWrapper {
    width: 100%;
}

.autoScaleContainer {
    margin: 0 auto;
}

