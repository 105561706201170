.acc-cell{
    text-align: right;
}
  
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--darkbg);
    font-size: 87.5%;
    opacity: 0.7;
}

::-moz-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--darkbg);
    font-size: 87.5%;
    opacity: 0.7;
}

:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--darkbg);
    font-size: 87.5%;
    opacity: 0.7;
}

::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--darkbg);
    font-size: 87.5%;
    opacity: 0.7;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--darkbg);
    font-size: 87.5%;
    opacity: 0.7;
}

/* For GamePlay transition */
.fade-enter {
    opacity: 0;
}

.fade-exit {
    opacity: 1;
}

.fade-enter-active {
    opacity: 1;
}

.fade-exit-active{
    opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
    -webkit-transition: opacity 500ms;
    -o-transition: opacity 500ms;
    -moz-transition: opacity 500ms;
    transition: opacity 500ms;
}