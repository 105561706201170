.fa-spin-hover {
    display: block;
    -webkit-animation: spin 0.4s infinite linear;
    -moz-animation: spin 0.4s infinite linear;
    animation: spin 0.4s infinite linear;
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(60deg);
                transform: rotate(60deg);
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(60deg);
             transform: rotate(60deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(60deg);
           -moz-transform: rotate(60deg);
             -o-transform: rotate(60deg);
                transform: rotate(60deg);
    }
}

.fa-shake-hover {
    display: block;
    -webkit-animation: fa-shake 1.5s infinite linear;
    -moz-animation: fa-shake 1.5s infinite linear;
    animation: fa-shake 1.5s infinite linear;
}

.fa-flip-hover path {
    -webkit-animation: animD 1.5s infinite linear;
    -moz-animation: animD 1.5s infinite linear;
    animation: animD 1.5s infinite linear;
}

@-webkit-keyframes animD {
    50% {
        d: path("M 64 32 C 28.7 32 0 60.7 0 96 V 416 c 0 35.3 28.7 64 64 64 H 384 c 35.3 0 64 -28.7 64 -64 V 96 c 0 -35.3 -28.7 -64 -64 -64 H 64 Z M 128 288 c 17.7 0 32 14.3 32 32 v 32 c 0 17.7 -14.3 32 -32 32 s -32 -14.3 -32 -32 v -32 c 0 -17.7 14.3 -32 32 -32 Z M 192 256 c 0 -17.7 14.3 -32 32 -32 s 32 14.3 32 32 v 96 c 0 17.7 -14.3 32 -32 32 s -32 -14.3 -32 -32 v -96 Z M 320 128 c 17.7 0 32 14.3 32 32 v 192 c 0 17.7 -14.3 32 -32 32 s -32 -14.3 -32 -32 v -192 c 0 -17.7 14.3 -32 32 -32 Z");
    }
}

@-moz-keyframes animD {
    50% {
        d: path("M 64 32 C 28.7 32 0 60.7 0 96 V 416 c 0 35.3 28.7 64 64 64 H 384 c 35.3 0 64 -28.7 64 -64 V 96 c 0 -35.3 -28.7 -64 -64 -64 H 64 Z M 128 288 c 17.7 0 32 14.3 32 32 v 32 c 0 17.7 -14.3 32 -32 32 s -32 -14.3 -32 -32 v -32 c 0 -17.7 14.3 -32 32 -32 Z M 192 256 c 0 -17.7 14.3 -32 32 -32 s 32 14.3 32 32 v 96 c 0 17.7 -14.3 32 -32 32 s -32 -14.3 -32 -32 v -96 Z M 320 128 c 17.7 0 32 14.3 32 32 v 192 c 0 17.7 -14.3 32 -32 32 s -32 -14.3 -32 -32 v -192 c 0 -17.7 14.3 -32 32 -32 Z");
    }
}

@keyframes animD {
    50% {
        d: path("M 64 32 C 28.7 32 0 60.7 0 96 V 416 c 0 35.3 28.7 64 64 64 H 384 c 35.3 0 64 -28.7 64 -64 V 96 c 0 -35.3 -28.7 -64 -64 -64 H 64 Z M 128 288 c 17.7 0 32 14.3 32 32 v 32 c 0 17.7 -14.3 32 -32 32 s -32 -14.3 -32 -32 v -32 c 0 -17.7 14.3 -32 32 -32 Z M 192 256 c 0 -17.7 14.3 -32 32 -32 s 32 14.3 32 32 v 96 c 0 17.7 -14.3 32 -32 32 s -32 -14.3 -32 -32 v -96 Z M 320 128 c 17.7 0 32 14.3 32 32 v 192 c 0 17.7 -14.3 32 -32 32 s -32 -14.3 -32 -32 v -192 c 0 -17.7 14.3 -32 32 -32 Z");
    }
}

.change-path path {
    d: path("M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z  M425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z")
}

.clicked-path path {
    d: path("M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
}

.fa-play-hover path {
    -webkit-animation: playSound 2s infinite linear;
    -moz-animation: playSound 2s infinite linear;
    animation: playSound 2s infinite linear;
}

@-webkit-keyframes playSound {
    0% {
        d: path("M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    17% {
        d: path("M 473.1 107 m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    33% {
        d: path("M 473.1 107 c 43.2 35.2 70.9 88.9 70.9 149 s -27.7 113.8 -70.9 149 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 475.3 341.3 496 301.1 496 256 s -20.7 -85.3 -53.2 -111.8 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    50% {
        d: path("M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z");
    }
    67% {
        d: path("M 473.1 107 c 43.2 35.2 70.9 88.9 70.9 149 s -27.7 113.8 -70.9 149 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 475.3 341.3 496 301.1 496 256 s -20.7 -85.3 -53.2 -111.8 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    83% {
        d: path("M 473.1 107 m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    100% {
        d: path("M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
}

@-moz-keyframes playSound {
    0% {
        d: path("M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    17% {
        d: path("M 473.1 107 m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    33% {
        d: path("M 473.1 107 c 43.2 35.2 70.9 88.9 70.9 149 s -27.7 113.8 -70.9 149 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 475.3 341.3 496 301.1 496 256 s -20.7 -85.3 -53.2 -111.8 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    50% {
        d: path("M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z");
    }
    67% {
        d: path("M 473.1 107 c 43.2 35.2 70.9 88.9 70.9 149 s -27.7 113.8 -70.9 149 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 475.3 341.3 496 301.1 496 256 s -20.7 -85.3 -53.2 -111.8 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    83% {
        d: path("M 473.1 107 m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    100% {
        d: path("M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
}

@keyframes playSound {
    0% {
        d: path("M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    17% {
        d: path("M 473.1 107 m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    33% {
        d: path("M 473.1 107 c 43.2 35.2 70.9 88.9 70.9 149 s -27.7 113.8 -70.9 149 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 475.3 341.3 496 301.1 496 256 s -20.7 -85.3 -53.2 -111.8 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    50% {
        d: path("M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z");
    }
    67% {
        d: path("M 473.1 107 c 43.2 35.2 70.9 88.9 70.9 149 s -27.7 113.8 -70.9 149 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 475.3 341.3 496 301.1 496 256 s -20.7 -85.3 -53.2 -111.8 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    83% {
        d: path("M 473.1 107 m -60.5 74.5 C 434.1 199.1 448 225.9 448 256 s -13.9 56.9 -35.4 74.5 c -10.3 8.4 -25.4 6.8 -33.8 -3.5 s -6.8 -25.4 3.5 -33.8 C 393.1 284.4 400 271 400 256 s -6.9 -28.4 -17.7 -37.3 c -10.3 -8.4 -11.8 -23.5 -3.5 -33.8 s 23.5 -11.8 33.8 -3.5 z M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
    100% {
        d: path("M 301.1 34.8 C 312.6 40 320 51.4 320 64 V 448 c 0 12.6 -7.4 24 -18.9 29.2 s -25 3.1 -34.4 -5.3 L 131.8 352 H 64 c -35.3 0 -64 -28.7 -64 -64 V 224 c 0 -35.3 28.7 -64 64 -64 h 67.8 L 266.7 40.1 c 9.4 -8.4 22.9 -10.4 34.4 -5.3 z");
    }
}


