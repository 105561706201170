.g1 {
    grid-column: 1;
}
  
.g2 {
    grid-column: 2;
}
  
.g3 {
    grid-column: 3;
}
  
.left {
    justify-self: start;
}
  
.right {
    justify-self: end;
}

@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

@-moz-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

@-webkit-keyframes animatebottom {
    from {bottom:-300px; opacity:0}
    to {bottom:0; opacity:1}
}

@-moz-keyframes animatebottom {
    from {bottom:-300px; opacity:0}
    to {bottom:0; opacity:1}
}

@keyframes animatebottom {
    from {bottom:-300px; opacity:0}
    to {bottom:0; opacity:1}
}

.gold-star-wrapper {
    vertical-align: middle !important;
}
